<template>
  <v-container fluid>
    <v-row
      justify="center"
      v-if="
        checkReadPermission($modules.facility.schedule.slug) &&
        checkReadPermission($modules.events.schedule.slug)
      "
      no-gutters
      class="mt-3 pb-4 scheduleSelection"
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-col md="2" justify="center">
        <router-link :to="`/calender`">
          <v-btn block light tile>Facility</v-btn>
        </router-link>
      </v-col>
      <v-col md="2" justify="center">
        <router-link :to="`/calendar/events`">
          <v-btn block color="#062b48" dark tile>Events</v-btn>
        </router-link>
      </v-col>
      <!-- <v-col
        md="2"
        justify="center"
        v-if="checkReadPermission($modules.workshops.schedule.slug)"
      >
        <router-link :to="`/workshop-schedule`">
          <v-btn block light tile>Workshop</v-btn>
        </router-link>
      </v-col> -->
      <v-spacer></v-spacer>
      <v-col md="2" class="text-lg-right mr-2">
        <v-select
          :items="venueServices"
          v-model="venueService"
          item-value="venue_service_id"
          item-text="name"
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          return-object
          background-color="#fff"
          dense
          @change="getCalender()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col sm="4">
        <v-btn @click="viewDay" light tile>Day</v-btn>
        <router-link :to="`/calendar/events`">
          <v-btn color="#062b48" dark tile>Month</v-btn>
        </router-link>
      </v-col>
      <v-col sm="4">
        <v-row no-gutters>
          <v-col sm="1" class="text-lg-center mr-1">
            <v-btn fab dark x-small color="teal" @click="prev">
              <v-icon dark>mdi-menu-left</v-icon>
            </v-btn>
          </v-col>
          <v-col sm="8" class="text-lg-center">
            <date-field v-model="focus" :button="true"> </date-field
          ></v-col>
          <v-col sm="1" class="text-lg-center ml-1">
            <v-btn fab dark x-small color="teal" @click="next">
              <v-icon dark>mdi-menu-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="4" class="text-lg-right">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col
            v-if="
              !checkReadPermission($modules.facility.schedule.slug) &&
              checkReadPermission($modules.events.schedule.slug)
            "
            sm="5"
            class="text-lg-right mr-2"
          >
            <v-select
              :items="venueServices"
              v-model="venueService"
              item-value="venue_service_id"
              item-text="name"
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
              background-color="#fff"
              dense
              return-object
              @change="getCalender()"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="'month'"
            @click:date="viewDay"
            @change="getCalender"
          >
            <template v-slot:event="{ event }">
              <div class="pl-4">
                {{ event.name }}
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
export default {
  data: () => ({
    focus: moment().format("YYYY-MM-DD"),
    events: [],
    venueService: {},
  }),
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.showLoader("Loading");
      this.$store.dispatch("loadVenueServices").then(() => {
        this.$nextTick(() => {
          if (this.$store.getters.getVenueServices.data.length) {
            this.getRouteParams();
            this.hideLoader();
            this.getCalender();
          }
        });
      });
    } else {
      if (this.$store.getters.getVenueServices.status) {
        this.getRouteParams();
        this.getCalender();
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    getRouteParams() {
      if (this.$route.params.data) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueService = data.venue_service;
        this.date = data.date;
      } else {
        this.venueService = this.$store.getters.getSportsService[0];
      }
    },
    getEventColor(event) {
      return event.color ? event.color : "teal";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getCalender() {
      if (this.venueService.venue_service_id == null) {
        this.$refs.calendar.checkChange();
        return;
      }
      this.$http
        .get(
          `venues/events/bookings/calender?venue_service_id=${this.venueService.venue_service_id}&date=${this.focus}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.events = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    viewDay() {
      this.$router.push({
        name: "EventScheduleWithParams",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service: this.venueService,
              date: this.focus,
            })
          ),
        },
      });
    },
  },
};
</script>